import { Injectable } from '@angular/core';
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription,interval,switchMap, take, takeWhile} from 'rxjs';
import { AppConstants } from 'src/app/core/appConstant';
import { AppInsightsService } from 'src/app/core/services/app-insights.service';
import {
  AdjustmentCodeDto,
  AdjustmentCodesResponseDto,
  GroupCodeResponseDto,
  LCNGroupCodeDetailsDto,
  LCNRemarkCodeDetailsDto,
  LcnAdjustmentCodeDto,
  RemarkCodeDto,
} from 'src/app/core/services/billingapi/billingfunctionapi.services';
import { DateConverterService } from 'src/app/core/services/dateconverter.service';
import { PersonStatusHistory } from 'src/app/core/services/peopleapi/peoplefunctionapi.services';
import { environment } from 'src/environments/environment';
import { ValidationErrorExpressionConstants } from '../constants/error-expression-constants';
import { StorageConstants } from '../constants/storage-constants';
import { IKendoGridColumnSetting } from '../models/IKendoGridColumnSetting';
import { IShifttTransactionType } from '../models/IShifttTransactionType';
import { FeatureStateService } from '../services/featureStateService';
import { CompanyConfigModel } from './../../core/services/u2api.services';
import { CommonConstants } from './../constants/common-constants';
import { KeycodeConstants } from './../constants/keycode-constants';
import { IPayRateMethodTypes } from '../models/IPayRateMethodTypes';

@Injectable({
  providedIn: 'root',
})
export class CommonFunctions {
  public showPersonTypePopup = false;
  statusDetails: PersonStatusHistory[];
  public dateConverterService = new DateConverterService();
  private readonly maxAttempts = 5;
  private attemptCount = 0;
  public getFormattedDate(dateString: string) {
    let date = '';
    if (dateString != null && dateString !== '') {
      const currentTime = new Date(dateString);
      const month = currentTime.getMonth() + 1;
      const day = currentTime.getDate();
      const year = currentTime.getFullYear();
      const monthStr = month < 10 ? '0' + month.toString() : month.toString();
      const dayStr = day < 10 ? '0' + day.toString() : day.toString();
      const yearStr = year.toString();
      date = monthStr + '/' + dayStr + '/' + yearStr;
    }
    return date;
  }

  public getApprovalStatusString(approvalStatus: string) {
    if (approvalStatus === 'Y' || approvalStatus === 'y') {
      approvalStatus = CommonConstants.Approved;
    }
    if (approvalStatus === 'N' || approvalStatus === 'n') {
      approvalStatus = CommonConstants.AprrovalPending;
    }
    return approvalStatus;
  }

  public getTrimmedString(inputString: string) {
    if (inputString != null && inputString !== '') {
      inputString = inputString.trim();
    }
    return inputString;
  }

  public handleErrorResponse(
    error: any,
    toastr: ToastrService,
    errorMessage: string = ValidationErrorExpressionConstants.serverErrorMessage) {
    const featureService = new FeatureStateService();
    if (featureService.u2LogErrorResponse()) {
      const appInsight = new AppInsightsService();
      appInsight.logError(error, undefined, undefined, true);
    }
    if (error) {
      if (typeof error === 'object') {
        if (error.error) {
          error = error.error;
        }
        if (error.response) {
          let responseObj;
          try {
            responseObj = JSON.parse(error.response);
          } catch {
            responseObj = error.response;
          }
          if (responseObj.Display && responseObj.Message) {
            toastr.error(responseObj.Message && responseObj.errors);
          } else if (error.status === 400) {
            toastr.error(Object.values(responseObj.errors)[0][0]);
          } else if (responseObj.title) {
            toastr.error(responseObj.title);
          } else if (responseObj.message) {
            toastr.error(responseObj.message);
          } else {
            toastr.error(errorMessage);
          }
        } else if (error.message) {
          toastr.error(error.message);
        } else if (error.status) {
          switch(error.status) {
            case 400:
              toastr.error('Bad Request');
              break;
            case 401:
            case 403:
              toastr.error('Unauthorized');
              break;
            case 404:
              toastr.error('Not Found');
              break;
            case 502:
              toastr.error('Bad Gateway');
              break;
            case 503:
              toastr.error('Service Unavailable');
              break;
            case 504:
              toastr.error('Gateway Timeout');
              break;
            default:
              toastr.error(errorMessage);
          }
        } else if (error.title) {
          toastr.error(error.title);
        } else {
          toastr.error(errorMessage);
        }
      } else {
        toastr.error(error);
      }
    } else {
      toastr.error(errorMessage);
    }
  }

  public showServerError(errorObj: any, toasterContext: ToastrService) {
    if (errorObj) {
      if (errorObj.response) {
        const responseObj = JSON.parse(errorObj.response);
        if (responseObj.Display) {
          toasterContext.error(responseObj.Message);
        } else {
          toasterContext.error(ValidationErrorExpressionConstants.serverErrorMessage);
        }
      } else {
        toasterContext.error(ValidationErrorExpressionConstants.serverErrorMessage);
      }
    }
  }

  public showErrorMessage(errorMessage: any, toasterContext: ToastrService) {
    if (errorMessage) {
      toasterContext.error(errorMessage);
    }
  }

  public compareValues(key, order = 'asc') {
    return function(a, b) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        return 0;
      }
      const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
      const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];
      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return order === 'desc' ? comparison * -1 : comparison;
    };
  }

  public getActiveStatusText() {
    return 'A';
  }

  public getInactiveStatusText() {
    return 'I';
  }

  public trimComment(text: string) {
    let trimText = text;
    if (trimText && trimText.length > 7) {
      trimText = trimText.substr(0, 7) + '...';
    }
    return trimText;
  }

  public getTimeFormatLookup() {
    const timeFormatLookup = [
      { Id: 1, Text: '24 hrs', Format: 'HH:mm', Placeholder: 'HH:MM' },
      { Id: 2, Text: '12 hrs', Format: 'hh:mm a', Placeholder: 'HH:MM A' },
    ];
    return timeFormatLookup;
  }

  public getValidDaysName(col: any) {
    let validDayName = '';
    const validDays: number[] = [];
    if (col.validDays) {
      const validDaysStr = col.validDays.split(',');
      for (let i = 0; i < validDaysStr.length; i++) {
        validDays.push(parseInt(validDaysStr[i], 10));
      }
      validDayName = this.getDaysAsText(validDays);
    }
    return validDayName;
  }

  public getDaysAsText(daysNumber: number[]): string {
    const daysString: string[] = [];
    daysNumber.forEach(function(val) {
      switch (val) {
        case 1:
          daysString.push('Sun');
          break;
        case 2:
          daysString.push('Mon');
          break;
        case 3:
          daysString.push('Tue');
          break;
        case 4:
          daysString.push('Wed');
          break;
        case 5:
          daysString.push('Thu');
          break;
        case 6:
          daysString.push('Fri');
          break;
        case 7:
          daysString.push('Sat');
          break;
      }
    });
    return daysString.toString();
  }

  public getDaysAsNumbers(daysString: string): number[] {
    const daysNumber: number[] = [];
    if (daysString != null && daysString !== '') {
      const daysStringArr = daysString.split(',');
      daysStringArr.forEach(day => {
        const val = day != null && day !== '' ? day.toLowerCase() : '';
        switch (val) {
          case 'sun':
            daysNumber.push(1);
            break;
          case 'mon':
            daysNumber.push(2);
            break;
          case 'tue':
            daysNumber.push(3);
            break;
          case 'wed':
            daysNumber.push(4);
            break;
          case 'thu':
            daysNumber.push(5);
            break;
          case 'fri':
            daysNumber.push(6);
            break;
          case 'sat':
            daysNumber.push(7);
            break;
        }
      });
    }
    return daysNumber;
  }

  public getDateWithoutTime(date?: Date) {
    if (date && date != null) {
      date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0));
    }
    return date;
  }

  public getTimeWithoutDate(date?: Date) {
    if (date && date != null) {
      date = new Date(
        Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), 0),
      );
    }
    return date;
  }

  public getDateWithTime(date?: Date, hours?: number, mins?: number, secs?: number) {
    if (date && hours && mins && secs) {
      date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), hours, mins, secs, 0));
    }
    return date;
  }

  public getToFixed(precesion: number, value: number) {
    if (value && value != null) {
      value = Number(value.toFixed(precesion));
    }
    return value;
  }

  public floatify(num: number) {
    return parseFloat(num.toFixed(10));
  }

  public listenValueChanges(currentForm: NgForm | FormGroup): Subscription {
    if (currentForm) {
      return currentForm.valueChanges.subscribe(x => {
        if (currentForm.dirty) {
          AppConstants.formDirty = true;
        }
      });
    }
    return null;
  }

  public unsubscribeValueChanges(sub: Subscription) {
    if (sub) {
      sub.unsubscribe();
    }
  }

  public getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }

  public deepCopy(obj) {
    let copy;
    if (null == obj || 'object' != typeof obj) {
      return obj;
    }
    if (obj instanceof Date) {
      copy = new Date();
      copy.setTime(obj.getTime());
      return copy;
    }
    if (obj instanceof Array) {
      copy = [];
      for (let i = 0, len = obj.length; i < len; i++) {
        copy[i] = this.deepCopy(obj[i]);
      }
      return copy;
    }
    if (obj instanceof Object) {
      copy = {};
      for (const attr in obj) {
        if (obj.hasOwnProperty(attr)) {
          copy[attr] = this.deepCopy(obj[attr]);
        }
      }
      return copy;
    }
  }

  public arrayMove(arr, fromIndex, toIndex) {
    const element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  }

  public getRandomIntInclusive(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1) + min); // The maximum is inclusive and the minimum is inclusive
  }

  public removeFormDirty() {
    AppConstants.formDirty = false;
  }

  public downloadExcelFile(data: any, filename: string) {
    const fileHeader = {
      'content-type': CommonConstants.ExcelContentType,
      'x-filename': filename,
    };
    this.downloadFile(data, fileHeader);
  }

  public downloadFile(data: any, headers: any): void {
    const responseHeaders = headers;
    const isSafari = /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);
    const blob = new Blob([data], {
      type: isSafari ? responseHeaders['content-type'] : CommonConstants.ExcelContentType,
    });

    if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
      (window.navigator as any).msSaveOrOpenBlob(blob, responseHeaders[CommonConstants.filenameHeader]);
    } else {
      const url = URL.createObjectURL(blob);

      const link = document.createElement('a') as any;
      document.body.appendChild(link);
      link.style = 'display: none';
      link.href = url;
      (link.download = responseHeaders[CommonConstants.filenameHeader]), link.click();
      setTimeout(() => {
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      }, 1000);
    }
  }

  public getAllTransactionTypes(): Array<IShifttTransactionType> {
    const transactionTypes: Array<IShifttTransactionType> = [
      {
        id: 1,
        transactionType: 'Shift',
      },
      {
        id: 2,
        transactionType: 'Mileage',
      },
      {
        id: 3,
        transactionType: 'Fee',
      },
      {
        id: 4,
        transactionType: 'Vendor Payment',
      },
      {
        id: 5,
        transactionType: 'Void Fee',
      },
      {
        id: 6,
        transactionType: 'Gross Hour Shift',
      },
      {
        id: 7,
        transactionType: 'Gross Hour Admin',
      },
      {
        id: 8,
        transactionType: 'Void',
      },
    ];
    return transactionTypes;
  }

  public getPayRateMethodTypes(): Array<IPayRateMethodTypes> {
    const payRateMethodTypes: Array<IPayRateMethodTypes> = [
      {
        id: 1,
        payRateMethod: 'Employer Rates',
      },
      {
        id: 2,
        payRateMethod: 'Participant Caregiver-Employer Pay Rates',
      },
      {
        id: 3,
        payRateMethod: 'Career Hour Pay Rates',
      },
    ];
    return payRateMethodTypes;
  }

  public getMessageInjectHeaders(): Array<{
    header: string;
    required: boolean;
  }> {
    return [
      { header: 'RecipientPersonId', required: true },
      { header: 'RecipientPersonType', required: true },
      { header: 'NotificationDate', required: true },
      { header: 'Priority', required: true },
      { header: 'NotificationMessage', required: true },
      { header: 'Regarding', required: true },
      { header: 'RegardingPersonType', required: true },
    ];
  }

  public getShiftInjectHeaders(): Array<{ header: string; required: boolean }> {
    return [
      { header: 'CaregiverId', required: true },
      { header: 'ParticipantId', required: true },
      { header: 'ServiceCode', required: true },
      { header: 'Tasks', required: false },
      { header: 'CaregiverComments', required: false },
      { header: 'ServiceDate', required: true },
      { header: 'InTime', required: true },
      { header: 'OutTime', required: true },
      { header: 'InAddress1', required: false },
      { header: 'InAddress2', required: false },
      { header: 'OutAddress1', required: false },
      { header: 'OutAddress2', required: false },
      { header: 'City', required: false },
      { header: 'Zip', required: false },
      { header: 'State', required: false },
      { header: 'Inlat', required: false },
      { header: 'Inlng', required: false },
      { header: 'Outlat', required: false },
      { header: 'Outlng', required: false },
      { header: 'IvrPhone', required: false },
      { header: 'EvvExId', required: false },
      { header: 'PSST', required: false },
    ];
  }

  public getInjectHeadersForFee(): Array<{
    header: string;
    required: boolean;
  }> {
    return [
      { header: 'ParticipantId', required: true },
      { header: 'ServiceCode', required: true },
      { header: 'ServiceDate', required: true },
      { header: 'Unit', required: false },
      { header: 'Note', required: false },
    ];
  }

  public getInjectHeadersForMileage(): Array<{
    header: string;
    required: boolean;
  }> {
    return [
      { header: 'ParticipantId', required: true },
      { header: 'CaregiverId', required: true },
      { header: 'ServiceCode', required: true },
      { header: 'ServiceDate', required: true },
      { header: 'Miles', required: true },
    ];
  }

  public getInjectHeadersForVoid(): Array<{
    header: string;
    required: boolean;
  }> {
    return [{ header: 'TCN', required: true }];
  }

  public getInjectHeadersForGhs(): Array<{
    header: string;
    required: boolean;
  }> {
    return [
      { header: 'ParticipantId', required: true },
      { header: 'CaregiverId', required: true },
      { header: 'ServiceCode', required: true },
      { header: 'GrossHourTotal', required: true },
      { header: 'ServiceDate', required: true },
      { header: 'PSST', required: false },
    ];
  }

  public getInjectHeadersForGhsV2(): Array<{
    header: string;
    required: boolean;
  }> {
    return [
      { header: 'ParticipantId', required: true },
      { header: 'CaregiverId', required: true },
      { header: 'ServiceCode', required: true },
      { header: 'Task', required: false },
      { header: 'CaregiverComments', required: false },
      { header: 'Units', required: true },
      { header: 'ServiceDate', required: true },
      { header: 'PSST', required: false },
    ];
  }

  public getInjectHeadersForGha(): Array<{
    header: string;
    required: boolean;
  }> {
    return [
      { header: 'CaregiverId', required: true },
      { header: 'ServiceCode', required: true },
      { header: 'GrossHourTotal', required: true },
      { header: 'ServiceDate', required: true },
      { header: 'EmployerId', required: true },
      { header: 'PSST', required: false },
    ];
  }

  public getInjectHeadersForGhaV2(): Array<{
    header: string;
    required: boolean;
  }> {
    return [
      { header: 'CaregiverId', required: true },
      { header: 'ServiceCode', required: true },
      { header: 'Units', required: true },
      { header: 'ServiceDate', required: true },
      { header: 'EmployerId', required: true },
      { header: 'PSST', required: false },
    ];
  }

  public getInjectHeadersForVoidFee(): Array<{
    header: string;
    required: boolean;
  }> {
    return [
      { header: 'ParticipantId', required: true },
      { header: 'TCN', required: true },
    ];
  }

  public validateLeapYear(date) {
    const dtArr = date.split('/');
    let isValid = false;
    if (dtArr && dtArr.length === 3) {
      isValid = !(
        Number(dtArr[0]) === 2 &&
        Number(dtArr[1]) === 29 &&
        !(Number(dtArr[2]) % 4 === 0 && (Number(dtArr[2]) % 100 !== 0 || Number(dtArr[2]) % 400 === 0))
      );
    }

    return isValid;
  }

  public convertStrArrayToIntArray(str, sep) {
    sep = typeof sep !== 'undefined' ? sep : '';
    return str
      .split(sep)
      .filter(function(val) {
        if (val === 'undefined' || val === null || val === '' || isNaN(val)) {
          return false;
        }
        return true;
      })
      .map(function(val) {
        return parseInt(val, 10);
      });
  }

  public base64ToArrayBuffer(base64) {
    const binaryString = window.atob(base64);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      const ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

  public setSpellCheckAttribute(document: Document) {
    const selector = 'input.k-textbox, input.k-input ,textarea';
    const textFields = document.querySelectorAll(selector);
    for (let i = 0; i < textFields.length; i++) {
      textFields[i].setAttribute('spellcheck', 'false');
    }
  }

  public formatNumeric(value: number, precision: number, fixedDecimal: number): string {
    if (value != null) {
      // If precision was sent in, round value to the precision
      if (precision != null) {
        const roundBy = Math.pow(10, precision);
        value = Math.round(value * roundBy) / roundBy;
      }
      // Handle formatting (number of digits after the decimal)
      if (fixedDecimal != null) {
        return value.toFixed(fixedDecimal);
      }
      return value.toString();
    }
    return '';
  }

  public formatAmount(value: number): string {
    if (value) {
      return '$' + this.addThousandSperator(value.toFixed(2));
    } else if (value === 0) {
      return '$' + value.toFixed(2);
    }
    return '';
  }

  public formatAccounting(value: number): string {
    if (value) {
      const fVal = '$' + this.addThousandSperator(Math.abs(value).toFixed(2));
      if (value < 0) {
        return '(' + fVal + ')';
      }
      return fVal;
    } else if (value === 0) {
      return '$' + value.toFixed(2);
    }
    return '';
  }

  public addThousandSperator(inAmount) {
    const partsAmount = inAmount.toString().split('.');
    partsAmount[0] = partsAmount[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return partsAmount.join('.');
  }

  public ProcessTimeRounding(dt: Date, config: CompanyConfigModel, processTimeNewDay: boolean): Date {
    if (config?.isRounding) {
      const result = this.ProcessTimeQuarterPunch(dt);
      if (result && processTimeNewDay) {
        return this.ProcessTimeNewDay(result);
      }
      return result;
    }
    return dt;
  }

  public ProcessTimeQuarterPunch(time: Date): Date {
    if (time) {
      return this.RoundTime(time);
    }
    return time;
  }

  public RoundTime(dt: Date) {
    let roundedDate: Date;
    const quaterTicks = 15 * 60 * 1000;
    const timeTicks = dt.getMinutes() * 60 * 1000;
    const mod = timeTicks % quaterTicks;
    if (mod > quaterTicks / 2) {
      const interval = 15 * 60 * 1000; // 15 minutes in milliseconds
      roundedDate = new Date(Math.ceil(dt.getTime() / interval) * interval);
    } else {
      roundedDate = new Date(Math.floor(dt.getTime() / quaterTicks) * quaterTicks);
    }

    return roundedDate;
  }

  public ProcessTimeNewDay(dt: Date) {
    if (dt) {
      if (dt.getHours() === 0 && dt.getMinutes() === 0 && dt.getSeconds() === 0) {
        return new Date(dt.setSeconds(-1));
      }
    }
    return dt;
  }

  public CalculateCalHrs(inTime: Date, outTime: Date) {
    const inTimeInMinutes = inTime.getHours() * 60 + inTime.getMinutes();
    const outTimeInMinutes = outTime.getHours() * 60 + outTime.getMinutes();
    return (outTimeInMinutes - inTimeInMinutes) / 60;
  }

  public IsMinutesInQuarter(time: Date) {
    const timeInMinutes = time.getMinutes();
    if (timeInMinutes % 15 === 0) {
      return true;
    } else {
      return false;
    }
  }

  public CalculateTimeFromCalhrs(inTime: Date, calHrs: number) {
    const outTime = inTime;
    const hours = Math.floor(calHrs);
    let remain = calHrs - hours;

    const m = 60 * remain;
    const min = Math.floor(m);
    remain = m - min;

    const s = 60 * remain;
    const sec = Math.floor(s);
    outTime.setHours(hours, min, sec, 0);
    return outTime;
  }

  public showLoading() {
    AppConstants.showLoading = true;
  }

  public hideLoading() {
    AppConstants.showLoading = false;
  }

  public validAmountKey(keyCode, value) {
    const inputValue = Number(value);
    const amountKeys = [KeycodeConstants.Backspace, KeycodeConstants.Delete, KeycodeConstants.Period];
    return !isNaN(inputValue) || amountKeys.indexOf(keyCode) > -1;
  }

  public parseQueryString(paramsObj: any) {
    const queryString = Object.keys(paramsObj)
      .map(key => {
        if (paramsObj[key]) {
          return encodeURIComponent(key) + '=' + encodeURIComponent(paramsObj[key]);
        }
      })
      .filter(Boolean)
      .join('&');
    return queryString ? '?' + queryString : '';
  }

  public redirect_Blank(url: string) {
    setTimeout(() => {
      localStorage.setItem(CommonConstants.renderBreadcrumbForIE, 'true');
    }, 300);
    const fullUrl = window.location.origin + '/#/' + url;
    window.open(fullUrl);
  }

  public openInNewTabWindow(fullUrl: string) {
    setTimeout(() => {
      localStorage.setItem(CommonConstants.renderBreadcrumbForIE, 'true');
    }, 300);
    window.open(fullUrl, CommonConstants.blankTab);
  }

  public openCrmProfile(crmKey: string): void {
    this.openInNewTabWindow(environment.crmCloudDomain + environment.crmCloudURLParameter1 +
      environment.crmCloudModelAppId + environment.crmCloudURLParameter2 + crmKey);
  }

  public formatNumber(value: number, decimals: number = 2): string {
    if (value) {
      return value.toFixed(decimals);
    }
    return '0';
  }

  public getRefeshDate(obj: any[]): Date {
    if (obj && obj.length > 0) {
      const mostRecentDate = new Date(
        Math.max.apply(
          null,
          obj.map(e => new Date(e.updatedOn)),
        ),
      );
      return mostRecentDate;
    } else {
      return null;
    }
  }

  public isObject(obj: any): number {
    if (obj) {
      return Object.keys(obj).length;
    } else {
      return 0;
    }
  }

  public addHoursToDate(value: Date, hours: number = 3) {
    if (value) {
      value.setHours(value.getHours() + hours);
    }
  }

  public setObfuscatedSSN(ssn: string) {
    if (ssn) {
      if (ssn.length > 4) {
        ssn = ssn.substring(ssn.length - 4, ssn.length);
      }
      return 'XXX-XX-' + ssn;
    } else {
      return ssn;
    }
  }

  public toggelPersonTypePopup(action: boolean) {
    this.showPersonTypePopup = action;
  }

  public trim(x: FormControl | { value: any } | string): string {
    if (x instanceof FormControl) {
      const newValue = this.trimAndNormalizeSpaces(x.value);
      x.setValue(newValue);
      return newValue;
    } else if (typeof x === 'string') {
      return this.trimAndNormalizeSpaces(x);
    } else if (x && typeof x === 'object' && 'value' in x) {
      x.value = this.trimAndNormalizeSpaces(x.value);
      return x.value;
    }
  }

  public trimAndNormalizeSpaces(value: string): string {
    return value
      .replace(/(^\s*)|(\s*$)/gi, '')
      .replace(/[ ]{2,}/gi, ' ')
      .replace(/\n +/, '\n');
  }

  public check_special_char(event) {
    const k = event.charCode;
    return k === 8 || (k >= 48 && k <= 57);
  }

  public projectLocalDateTimeAsUtc(dateVal: Date) {
    return new Date(
      dateVal.getFullYear() +
        '-' +
        (dateVal.getMonth() + 1) +
        '-' +
        dateVal.getDate() +
        ' ' +
        dateVal.getHours() +
        ':' +
        dateVal.getMinutes() +
        ':' +
        dateVal.getSeconds() +
        'Z',
    );
  }

  public resetFormDirty(form: FormGroup) {
    form.markAsPristine();
  }

  public removeExtraSpaces(str: string) {
    return str.replace(/\s\s+/g, ' ').trim();
  }

  public isNull(str: string, str2: string) {
    return str || str2;
  }

  public addSpaceForPascalCase(str: string) {
    if (!str) {
      return str;
    }
    return str.replace(/([A-Z])/g, ' $1').trim();
  }

  onHistoryLookupEvent(event: PersonStatusHistory[], form: FormGroup, personStatus: any, mdlStatusData: any) {
    if (event) {
      const result = this.OnHistoryLookupChangeEvent(event);
      let statusItem: any;
      if (mdlStatusData) {
        statusItem = mdlStatusData.filter(x => x.key.trim() === result.statususer)[0];
      }
      if (personStatus) {
        statusItem = personStatus.filter(x => x.id.trim() === result.statususer)[0];
      }
      form.patchValue({
        status: statusItem,
        StatusComboBox: statusItem,
        statusStartDate: result.startDate ? result.startDate : undefined,
        statusEndDate: result.endDate ? result.endDate : undefined,
      });
      return this.statusDetails;
    }
  }

  OnHistoryLookupChangeEvent(event: PersonStatusHistory[]) {
    const result = this.SetStatusDetail(event);
    const statususer = result.statususer;
    const startDate = result.startDate;
    const endDate = result.endDate;
    this.statusDetails = result.statusDets;
    return { statususer, startDate, endDate };
  }

  SetStatusDetail(event: PersonStatusHistory[]) {
    let statususer = 'IA';
    let startDate;
    let endDate;
    const todaysdate = this.dateConverterService.convertToUTCDate(new Date());
    let statusDets;
    if (event) {
      event.forEach(element => {
        const status = new PersonStatusHistory();
        status.personInternalId = element.personInternalId;
        status.status = element.status;
        status.statusStartDate = element.statusStartDate
          ? this.dateConverterService.projectUTCDateTimeAsLocalDateTime(element.statusStartDate)
          : undefined;
        status.statusEndDate = element.statusEndDate
          ? this.dateConverterService.projectUTCDateTimeAsLocalDateTime(element.statusEndDate)
          : undefined;
        status.personInternalId = element.personInternalId;
        status.personStatusId = element.personStatusId;
        status.personStatus = element.personStatus;
        if (!statusDets) {
          statusDets = Array<PersonStatusHistory>();
        }
        if (
          status.statusStartDate &&
          status.statusStartDate <= todaysdate &&
          (!status.statusEndDate || (status.statusEndDate && status.statusEndDate >= todaysdate))
        ) {
          statususer = status.status;
          startDate = status.statusStartDate;
          endDate = status.statusEndDate;
        }
        statususer = status.status && status.status !== statususer ? status.status : statususer;
        statusDets.push(status);
      });
    }

    return { statususer, startDate, endDate, statusDets };
  }

  DisplayProfileAddressPhone(cellPhone, homePhone, personDetail) {
    cellPhone.sort(function(x, y) {
      return x.isDefault === true ? -1 : y.isDefault === true ? 1 : 0;
    });
    homePhone.sort(function(x, y) {
      return x.isDefault === true ? -1 : y.isDefault === true ? 1 : 0;
    });
    personDetail.mailingAddresses.slice().sort(function(x, y) {
      return x.isDefault === true ? -1 : y.isDefault === true ? 1 : 0;
    });
    personDetail.physicalAddresses.slice().sort(function(x, y) {
      return x.isDefault === true ? -1 : y.isDefault === true ? 1 : 0;
    });
  }

  public trimScreenName(screenName: string, allowedChars: number = 20) {
    const ellipsesCount = 3;
    if (screenName && allowedChars > 0 && allowedChars - ellipsesCount > 0) {
      screenName = screenName.length > allowedChars ? screenName.substring(0, allowedChars - ellipsesCount) + '...' : screenName;
    }
    return screenName;
  }

  public setPlaceHolder(elementId: string, form: FormGroup, placeholderText: string) {
    if (this.getBrowserName() === 'ie') {
      document.getElementById(elementId).setAttribute('placeholder', placeholderText);
      if (!form.dirty) {
        form.markAsPristine();
      }
    } else {
      document.getElementById(elementId).setAttribute('placeholder', placeholderText);
    }
  }

  public getFormattedInsuredIds(insuredIds: string, isTooltip: boolean = false) {
    if (!insuredIds) {
      return insuredIds;
    }
    if (isTooltip) {
      return insuredIds.split(', ').length > 1 ? insuredIds : undefined;
    } else {
      return insuredIds.split(', ').length > 1 ? 'Multiple' : insuredIds;
    }
  }

  public getFormattedEIN(ein: string) {
    if (ein.trim().length === 9) {
      let formattedEIN = '';
      const einStart = ein.substr(0, 2);
      const einEnd = ein.substr(2, 7);
      formattedEIN += einStart + '-' + einEnd;
      return formattedEIN;
    } else {
      return CommonConstants.PendingDetail;
    }
  }

  public getFormattedEINList(einString: string) {
    einString = einString.trim();
    const einList = einString.split(',');
    const length = einList.length;
    if (length === 0) {
      einList[0] = CommonConstants.PendingDetail;
    } else {
      for (let i = 0; i < length; i++) {
        einList[i] = this.getFormattedEIN(einList[i]);
        if (i < length - 1) {
          einList[i] += ',';
        }
      }
    }
    return einList;
  }

  public getUnFormattedEIN(ein: string) {
    return ein.replace(/-/g, '');
  }

  public setMaskedFocus(element: any) {
    if (element?.target?.value) {
      const elmValue = element.target.value;
      const numericString = elmValue.replace(/[^0-9]/g, '');
      const position = numericString && numericString.length > 0 ? elmValue.indexOf('_') : 0;
      setTimeout(function() {
        if (element.target.setSelectionRange) {
          element.target.setSelectionRange(position, position);
        }
      }, 1);
    }
  }

  public convertFromDisplayTimeToValidTimeSpan(hour: number, minute: number) {
    if (hour > 23 && minute >= 0) {
      return CommonConstants.maxTimeStampWithSecondsValue;
    } else {
      return hour + ':' + minute;
    }
  }

  public toggleMultiSelect(hide: boolean) {
    const ta = document.getElementsByClassName('k-reset') as HTMLCollectionOf<HTMLElement>;
    if (ta[0]) {
      if (hide) {
        ta[0].style.display = 'none';
      } else {
        ta[0].style.display = 'block';
      }
    }
  }

  public convertFromValidTimeSpanToDisplayTime(hour: number, minute: number) {
    if (hour === 23 && minute === 0.98) {
      return CommonConstants.maxDisplayTimeValue;
    }
  }

  public onMultiSelectClose(event: any, multiselect: any) {
    event.preventDefault();
    // Close the list if the component is no longer focused
    setTimeout(() => {
      if (!multiselect.wrapper.nativeElement.contains(document.activeElement)) {
        multiselect.toggle(false);
      }
    });
  }

  public getAdjustmentCode(adjustmentCodeIds: LcnAdjustmentCodeDto[], adjustmentCodeObjs: AdjustmentCodesResponseDto[]): string {
    if (adjustmentCodeIds?.length > 0) {
      const reasonCodes = [];
      adjustmentCodeIds.forEach((val, index) => {
        if (val.adjustmentCodeId > 0) {
          const adjustmentCode = adjustmentCodeObjs.find(x => x.adjustmentCodeId === adjustmentCodeIds[index].adjustmentCodeId)
            ?.adjustmentCode;
          if (adjustmentCode) {
            reasonCodes.push(adjustmentCode);
          }
        }
      });
      return reasonCodes.join(', ');
    }
    return null;
  }
  public getRemarkCode(RemarkCodeIds: LCNRemarkCodeDetailsDto[], remarkCodeObjs: RemarkCodeDto[]): string {
    if (RemarkCodeIds?.length > 0) {
      const reasonCodes = [];
      RemarkCodeIds.forEach((val, index) => {
        if (val.remarkCodeId > 0) {
          const remarkCode = remarkCodeObjs.find(x => x.remarkCodeId === RemarkCodeIds[index].remarkCodeId)?.remarkCode;
          if (remarkCode) {
            reasonCodes.push(remarkCode);
          }
        }
      });
      return reasonCodes.join(', ');
    }
    return null;
  }

  public getAdjustmentCodesForPayments(adjustmentCodeIds: LcnAdjustmentCodeDto[]): any {
    if (adjustmentCodeIds?.length > 0) {
      const reasonCodes = [];
      const reasonCodeObjs = [];
      const reasonCodesIds = [];
      adjustmentCodeIds.forEach(val => {
        if (val.adjustmentCodeId > 0 && val.adjustmentCode) {
          reasonCodesIds.push(val.adjustmentCodeId);
        }
        const adj = new AdjustmentCodeDto();
        adj.adjustmentCode = val.adjustmentCode;
        adj.adjustmentCodeId = val.adjustmentCodeId;
        reasonCodeObjs.push(adj);
        reasonCodes.push(adj.adjustmentCode);
      });

      return {
        ids: reasonCodesIds,
        codes: reasonCodes.join(),
        adjCodes: reasonCodeObjs,
      };
    }
    return null;
  }
  public getRemarkCodesForPayments(RemarkCodeIds: LCNRemarkCodeDetailsDto[]): any {
    if (RemarkCodeIds?.length > 0) {
      const reasonCodes = [];
      const reasonCodeObjs = [];
      const reasonCodesIds = [];
      RemarkCodeIds.forEach(val => {
        if (val.remarkCodeId > 0 && val.remarkCode) {
          reasonCodesIds.push(val.remarkCodeId);
        }
        const adj = new RemarkCodeDto();
        adj.remarkCode = val.remarkCode;
        adj.remarkCodeId = val.remarkCodeId;
        reasonCodeObjs.push(adj);
        reasonCodes.push(adj.remarkCode);
      });

      return {
        ids: reasonCodesIds,
        codes: reasonCodes.join(),
        remCodes: reasonCodeObjs,
      };
    }
    return null;
  }

  public getGroupCode(GroupCodeIds: LCNGroupCodeDetailsDto[], groupCodeObjs: GroupCodeResponseDto[]): string {
    if (GroupCodeIds?.length > 0) {
      const reasonCodes = [];
      GroupCodeIds.forEach((val, index) => {
        if (val.groupCodeId > 0) {
          const groupCode = groupCodeObjs.find(x => x.groupCodeId === GroupCodeIds[index].groupCodeId)?.groupCode;
          if (groupCode) {
            reasonCodes.push(groupCode);
          }
        }
      });
      return reasonCodes.join(', ');
    }
    return null;
  }

  public getItemFromOptions(id: string, options: any[], idName) {
    for (const option of options) {
      if (this.getProp(option, idName) === id) {
        return option;
      }
    }
    return id;
  }

  public getSelectValue(value, column: IKendoGridColumnSetting) {
    if (value === null || value === undefined) {
      if (
        column.changeValueToDefaultConditional !== undefined &&
        column.changeValueToDefaultConditional(value) &&
        column.defaultDisplayValue !== null &&
        column.defaultDisplayValue !== undefined
      ) {
        return column.defaultDisplayValue;
      }
      return null;
    }
    if (column?.attributes !== undefined && column?.attributes !== null && column?.attributes['selectType'] === 'withId') {
      const temp = column.options.find(option => option[column.valueField] === value);
      return temp ?? value;
    }
    return value;
  }

  public getProp(obj, prop) {
    if (typeof obj !== 'object') {
      throw new Error('getProp: obj is not an object');
    }
    if (typeof prop !== 'string') {
      throw new Error('getProp: prop is not a string');
    }

    // Replace [] notation with dot notation
    prop = prop.replace(/\[["'`](.*)["'`]\]/g, '.$1');

    return prop.split('.').reduce(function(prev, curr) {
      return prev ? prev[curr] : undefined;
    }, obj || self);
  }

  public replaceNotificationSubstring(message: string, placeholderText: string, replacePlaceholderTextWith: string) {
    return message.replace(placeholderText, replacePlaceholderTextWith);
  }

  public delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  public removeNull(data) {
    if (data) {
      Object.keys(data).forEach(key => {
        if (data[key] === null) {
          delete data[key];
        }
      });
    }
    return data;
  }

  public convertUTCDateToLocalDate(date) {
    const newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
    const offset = date.getTimezoneOffset() / 60;
    const hours = date.getHours();
    newDate.setHours(hours - offset);
    return newDate;
  }

  public openIntegrationJobsHistoryInNewTab(jobCode: string, displayText = 'Payroll') {
    const fullUrl = window.location.origin + '/#/' + 'Maintenance/IntegrationJobs';
    localStorage.setItem(StorageConstants.RedirectUri, fullUrl);
    localStorage.setItem(
      StorageConstants.IntegrationJobHistoryRequest,
      JSON.stringify({
        selectedCategoryText: '{"displayText": "'+ displayText +'"}',
        selectedJobCode: jobCode,
        selectedJobStatuses: [
          '{"displayText": "Pre-Processing"}',
          '{"displayText": "Pending To Be Picked"}',
          '{"displayText": "Processing"}',
          '{"displayText": "Job Queued"}',
        ],
      }),
    );
    this.openInNewTabWindow(fullUrl);
  }

  getFromBetween = {
    results: [],
    str: '',
    getFromBetween(sub1, sub2) {
      if (this.str.indexOf(sub1) < 0 || this.str.indexOf(sub2) < 0) {
        return false;
      }
      const SP = this.str.indexOf(sub1) + sub1.length;
      const string1 = this.str.substr(0, SP);
      const string2 = this.str.substr(SP);
      const TP = string1.length + string2.indexOf(sub2);
      return this.str.substring(SP, TP);
    },
    removeFromBetween(sub1, sub2) {
      if (this.str.indexOf(sub1) < 0 || this.str.indexOf(sub2) < 0) {
        return false;
      }
      const removal = sub1 + this.getFromBetween(sub1, sub2) + sub2;
      this.str = this.str.replace(removal, '');
    },
    getAllResults(sub1, sub2) {
      // first check to see if we do have both substrings
      if (this.str.indexOf(sub1) < 0 || this.str.indexOf(sub2) < 0) {
        return;
      }

      // find one result
      const result = this.getFromBetween(sub1, sub2);
      // push it to the results array
      this.results.push(result);
      // remove the most recently found one from the string
      this.removeFromBetween(sub1, sub2);

      // if there's more substrings
      if (this.str.indexOf(sub1) > -1 && this.str.indexOf(sub2) > -1) {
        this.getAllResults(sub1, sub2);
      } else {
        return;
      }
    },
    get(stri, sub1, sub2) {
      this.results = [];
      this.str = stri;
      this.getAllResults(sub1, sub2);
      return this.results;
    },
  };

  public removeDuplicatesFunc(myArr: any[], prop: string) {
    return myArr.filter((obj, pos, arr) => arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos);
  }

  public disableFormControls(form: FormGroup, controlNames: string[]) {
    controlNames.forEach(cntl => {
      form.controls[cntl].disable();
    });
  }

  public getLoadingStatus(loading: any) {
    if (loading) {
      return (Object.values(loading).find(val => val === true) !== undefined);
    }
    return false;
  }
  waitForSessionStorage(key: string): Observable<boolean> {
    return interval(2000).pipe(
      take(this.maxAttempts),
      switchMap(() => this.checkSessionOnce(key)),
      takeWhile(sessionExists => !sessionExists, true)
    );
  }

  private checkSessionOnce(key: string): Observable<boolean> {
    this.attemptCount++;
    const sessionExists = !!sessionStorage.getItem(key);
    return new Observable<boolean>(observer => {
      observer.next(sessionExists);
      observer.complete();
    });
  }
}
